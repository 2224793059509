<template>
  <div class="tableClass">
    <div class="cui__utils__heading mb-0">
      <strong>Rules</strong>
    </div>
    <div class="text-muted mb-3">On this page you can view, search, edit class</div>
    <hr />
    <div class="d-flex flex-nowrap">
      <div class>
        <a-form-item>
          <a-input
            v-model="search"
            @keyup="searchClass"
            placeholder="Search by tingkat simbol or wali kelas..."
            :style="{
              width: isMobile ? '100%' : '320px',
              height: '40px'
            }"
          ></a-input>
        </a-form-item>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="Nik" slot-scope="text">{{text ? text : '-'}}</span>
      <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toDetailClass(record.key)" class="btn btn-outline-success mx-2">Detail</a>
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Tingkat',
    dataIndex: 'Tingkat',
    // width: '8%',
    sorter: true,
    scopedSlots: {
      customRender: 'Tingkat',
    },
  },
  {
    title: 'Simbol',
    dataIndex: 'Simbol',
    sorter: true,
    // width: '8%',
  },
  {
    title: 'Wali Kelas',
    dataIndex: 'WaliKelas',
    // width: '25%',
  },
  {
    title: 'Tahun Ajaran',
    dataIndex: 'TahunAjaran',
    // width: '15%',
  },
  {
    title: 'Zoom ID',
    dataIndex: 'ZoomId',
    // width: '12%',
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: '15%',
    scopedSlots: { customRender: 'Actions' },
  },
]
export default {
  data() {
    return {
      search: '',
      columns,
      dataTable: [],
      pagination: {},
      loading: false,
    }
  },
  methods: {
    toDetailClass(id) {
      this.$router.push({ name: 'Detail Class Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Class Admin'],
      })
    },
    searchClass() {
      // if (this.search.length > 2) {
      this.fetchData({
        search: this.search,
      })
      // } else {
      //   this.fetchData()
      // }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchData({
        page: pagination.current,
        order: order,
      })
    },
    fetchData(params = { page: 1, order: 'ASC', search: '' }) {
      this.loading = true
      this.$store.dispatch('admin/FETCH_CLASSES', { page: params.page, order: params.order, search: params.search })
        .then(data => {
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loading = false
          this.dataTable = data.kelas.map(row => {
            return {
              key: row.id,
              Tingkat: row.tingkat,
              Simbol: row.simbol,
              WaliKelas: row.guru ? row.guru.nama : '-',
              TahunAjaran: `${row.tahun_ajaran.semester} (${row.tahun_ajaran.tahun})`,
              ZoomId: row.zoom_url,
            }
          })
          this.pagination = pagination
        })
    },
  },
  created() {
    this.fetchData()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.tableClass {
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #f56a79 !important;
    color: white !important;
    color: #f56a79 !important;
  }

  .btn.btn-outline-danger:hover,
  .btn.btn-outline-danger:active,
  .show > .btn.btn-outline-danger:hover,
  .show > .btn.btn-outline-danger:active {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}
</style>
