<template>
  <div>
    <h1>Classes</h1>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <TableClass />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import TableClass from '@/components/app/Admin/TableClass'

export default {
  components: {
    TableClass,
  },
}
</script>

<style>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}
</style>
